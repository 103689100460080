.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ck-editor__editable {
  min-height: 200px;
}

.primary-title {
  color: #012970;
  font-size: 20px;
}

.sequence-item-card {
  border: 1px dashed lightgray;
  min-width: 200px;
  width: fit-content;
  padding: 5px;
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
}

.sequence-items {
  overflow-y: auto;
  min-height: 60vh;
  max-height: 60vh;
  margin: auto;
  text-align: center;
  padding-top: 1rem;
}

.grey-border {
  border: 1px solid grey;
}

.red-border {
  border: 1px solid red;
}

.drop-here-message {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.blur {
  opacity: 0.8;
}

.hide {
  display: none;
}

.order-options {
  width: 100px !important;
}

.arrow-text {
  background-color: #61dafb;
  color: #000;
  display: inline-block;
  padding-left: 8px;
  font-weight: 600;
}

.arrow {
  border-style: dashed;
  border-color: transparent;
  border-width: 0.2em;
  display: -moz-inline-box;
  display: inline-block;
  /* Use font-size to control the size of the arrow. */
  font-size: 100px;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: middle;
  width: 0;
  background-color: #fff; /* change background color acc to bg color */
  border-left-width: 0.2em;
  border-left-style: solid;
  border-left-color: #61dafb;
  left: 0.25em;
}

.hover-pointer:hover {
  cursor: pointer;
}

.default-option:invalid {
  color: gray;
}

.content-order {
  min-width: 40px;
}

.text-bg {
  font-size: 18px;
}

.pagination__btn {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination__btn--active {
  background-color: lightblue;
}

.nav-item > .active {
  border-bottom: 1.5px solid blue;
  padding-bottom: 5px !important;
}

pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
  overflow-x: scroll;
  border-radius: 5px;
}

#embedCodeForm input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding: 1px;
  margin-right: 10px;
}

.StripeElement {
  display: block;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 10px 14px;
  font-size: 1em;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  outline: 0;
}

.StripeElement--focus {
  border-bottom: 2px solid #0d6efd;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.datetime {
  display: inline;
  padding: 0.575rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.date-label {
  font-size: 1.2rem;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .hidden-sm {
    display: none;
  }

  .date-range-text {
    display: block;
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 600px) {
  .hidden-md {
    display: none;
  }
}

.stats-card {
  min-width: 230px;
}

.stat-table > div > div > div > table > * > * > * {
  padding: 0.25rem 0.25rem !important;
}
